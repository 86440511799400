<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader>
      <template #header>
        <img
          src="@/assets/plugins/demo_plugin.png"
          alt="demo logo"
          height="32"
        >
        {{ installed ? 'Update ' : 'Install' }} Demo Plugin
      </template>
      <template #subheader>
        <v-alert type="error" class="text-center">
          WARNING: Installing this Demo plugin will prevent any emails from being sent from any email campaigns.
          Instead, it will create fake email actions for the contacts in the campaign.
        </v-alert>
      </template>
    </PageHeader>

    <div class="mt-10">
      <validation-observer
        ref="inputRef"
      >
        <div>
          <v-checkbox
            v-model="activateDateIncrement"
            :false-value="false"
            class="mt-5"
            label="Activate Date Increment"
          />
        </div>
      </validation-observer>
      <v-row class="mt-12">
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            v-if="installed"
            class="custom-button custom-button--red-text px-8 mr-4"
            height="34px"
            depressed
            @click="deactivateDialog = true;"
          >
            Deactivate
          </v-btn>

          <v-btn
            class="custom-button custom-button--blue px-13"
            height="34px"
            depressed
            :block="$vuetify.breakpoint.xsOnly"
            @click="trigger"
          >
            {{ plugin ? 'Update' : 'Install' }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <DeactivateConfirmDialog
      v-if="deactivateDialog"
      :id="id"
      :plugin="plugin"
      @dismiss="deactivateDialog = false;"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import DeactivateConfirmDialog from "./components/DeactivateConfirmDialog";

export default {
  name: "AddPluginDemo",
  metaInfo: {
    title: 'Install Demo'
  },
  components: {
    DeactivateConfirmDialog,
    PageHeader,
  },
  props: {
    id: {
      default: () => null,
      type: [Number, String],
    },
    installed: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    deactivateDialog: false,
    options: [
    ],
    plugin: null,
    activateDateIncrement: false
  }),
  async created() {
    if (this.installed) {
      await this.loadAccountPlugin();
    }
  },
  methods: {
    async loadAccountPlugin() {
      const response = await this.$rest.plugins.get_resource(this.id, {
        includeCredentials: true,
      });
      if (!response.data) {
        return;
      }

      this.plugin = {...response.data};
      this.activateDateIncrement = this.plugin?.pluginConfiguration?.activateDateIncrement;
    },
    trigger() {
      if (this.plugin) {
        return this.updatePlugin();
      } else {
        return this.installPlugin();
      }
    },
    async updatePlugin() {
      const pluginConfiguration = {
        activateDateIncrement: this.activateDateIncrement,
        credentials: {
        },
      };

      const response = await this.$rest.plugins.put_resource(this.id, {
        ...this.plugin,
        plugin: this.plugin.plugin.id,
        pluginConfiguration
      });

      if (!response.data) {
        return this.$store.commit('snackbar/showMessage', {
          content: 'Failed to update plugin',
          color: 'error',
        });
      }

      this.$store.commit('snackbar/showMessage', {
        content: 'Plugin updated successfully',
        color: 'info',
      });
      setTimeout(() => {
        this.$router.push({
          name: "Plugins",
        })
      }, 500);
    },
    async installPlugin() {
      const pluginConfiguration = {
        activateDateIncrement: this.activateDateIncrement,
        credentials: {
        },
      };
      const response = await this.$rest.plugins.post_resource({
        plugin: +this.id,
        isActive: true,
        pluginConfiguration,
      });
      if (!response.data) {
        return this.$store.commit('snackbar/showMessage', {
          content: 'Failed to install plugin',
          color: 'error',
        });
      }

      const selected_account = this.$store.getters["user/account"];
      this.$store.commit("user/update_selected_account", {
        ...selected_account,
        activePlugins: [...selected_account.activePlugins, response.data],
      });

      this.$store.commit('snackbar/showMessage', {
        content: 'Installed plugin successfully',
        color: 'info',
      });
      setTimeout(() => {
        this.$router.push({
          name: "Plugins",
        })
      }, 500);
    },
  },
}
</script>

<style scoped>

</style>
